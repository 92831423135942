import { PaymentGatewayProvider } from "./PaymentGatewayProvider";
import { BaseViewModel } from "./ViewModels/BaseViewModel";


export class Cayan implements PaymentGatewayProvider {
    
    enterPaymentsManually(createTransactionViewModel: CreateTransactionViewModel,
        isBusy: (b: boolean) => any,
        forgeryToken: string,
        openPaymentWindow: (w: Window) => any,
        onError: (reply: any) => any) 
    {

        let viewModel: CreateTransactionViewModel = new CreateTransactionViewModel(createTransactionViewModel);
        viewModel.companyName = localStorage.CompanySettings ? JSON.parse(localStorage.CompanySettings).CompanyName : '';

        let ajaxSettings: any = {
            type: 'POST',
            url: '/PaymentGateway/CreateTransaction',
            headers: { 'VerificationToken': forgeryToken },//$.find("#forgeryToken").val() },
            contentType: 'application/json',
            data: JSON.stringify(viewModel),
            success(reply) {
                if (reply.isRedirect) {
                    window.location.href = reply.redirectUrl;
                }
                else {
                    if (reply) {
                        if (onError && reply.error) {
                            onError(reply);
                        }
                        else {
                            var URL = reply.result.URL + "?transportKey=" + reply.result.TransportKey;
                            openPaymentWindow(window.open(URL, "_self", "toolbar=no,resizable=no"));
                        }
                    }
                }
            },

            error(err) {
                console.log(err.status + ' : ' + err.statusText);
            },
            always() {
                isBusy(false);
                //self.IsBusy(false);
            }
        }
        $.ajax(ajaxSettings);
          
    }
}

class CreateTransactionViewModel extends BaseViewModel {

    constructor(viewModel: CreateTransactionViewModel) {

        super(viewModel);
    }
}