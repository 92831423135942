import $ from "jquery";
import * as ClearentResponse from "./ClearentResponse";
import { UrlHelper } from "./../../Helpers";

export class ClearentResponseHandlerStrategy {

    public static paymentHandlerStrategyType: ClearentPaymentResponseHandlerBase;

    clearentOnError(responseRaw: string, responseJson: ClearentResponse.Response): void {

        console.log("Transaction failed");
        console.log(responseRaw);
        console.log(responseJson);
    }

    //note:  'this' keyword will not work here as these functions get called by Clearent and 'this' is overwritten.
    clearentOnSuccess(responseRaw: string, responseJson: ClearentResponse.Response): void {
        $('#spanError').hide();


        console.log("Transaction successful");
        console.log(responseRaw);
        console.log(responseJson);
        if (ClearentConstants.saveCard && !ClearentBehavior.isForMobile) {
            var payload = { "response": JSON.stringify(responseJson) };
            payload["viewModel"] = { entityId: ClearentConstants.entityId, PaymentIndex: ClearentConstants.paymentIndex };

            const ajaxSettings: any = {
                type: "POST",
                url: "/PaymentGateway/PostSavedCard",
                contentType: "application/json",
                data: JSON.stringify(payload),
                success: reply => {
                },
                error: err => {
                    console.log(err.status + " : " + err.statusText);
                }
            };
            $.ajax(ajaxSettings).done(function () {

                //Note: $.ajax.always wasn't working

                //We were running into issues where the page was navigating back before the saved card was persisted to the db.
                //So when saving the card token, wait for that to finish before proceeding with the rest
                ClearentResponseHandlerStrategy.ClearentSuccess(responseRaw, responseJson);
            });
        }
        else {
            ClearentResponseHandlerStrategy.ClearentSuccess(responseRaw, responseJson);
        }


    }


    static ClearentSuccess(responseRaw: string, responseJson: ClearentResponse.Response) {
        if (ClearentResponseHandlerStrategy.paymentHandlerStrategyType && ClearentResponseHandlerStrategy.paymentHandlerStrategyType.clearentOnSuccess) {
            ClearentResponseHandlerStrategy.paymentHandlerStrategyType.clearentOnSuccess(responseRaw, responseJson);
        }
    }

    ClearentOnPopupClosed(responseRaw: string, responseJson: ClearentResponse.Response): void {
        ClearentBehavior.goBack();
    }
}

export class ClearentSavePaymentOnlyResponseHandler {
    clearentOnSuccess(responseRaw: string, responseJson: ClearentResponse.Response): void {
        //Keeps backward compatibility with the old routes
        var fromVue = ClearentConstants.redirectUrl.includes("#")//Remove this once we take the Agreement Page out of Feature flag
        if (fromVue)
            ClearentConstants.redirectUrl = ClearentConstants.redirectUrl + '/' + ClearentConstants.paymentIndex;
        ClearentBehavior.goBack();
    }
}

export abstract class ClearentPaymentResponseHandlerBase {


    //note:  'this' keyword will not work here as these functions get called by Clearent and 'this' is overwritten.
    clearentOnSuccess(responseRaw: string, responseJson: ClearentResponse.Response): void {
        $('#spanError').hide();

        let amount = responseJson.payload.transaction.amount;
        let authCode = responseJson.payload.transaction['authorization-code'];

        responseJson.raw = responseRaw;
        if (ClearentBehavior.isForMobile) {
            ClearentBehavior.navigateToMobile(responseJson);
        }
        else {
            //JoGo 3/25/19 - prevent user from navigating away until PostPayment has completed
            $("#Clearent-close-button").prop("disabled", true);

            var payload = { "response": JSON.stringify(responseJson) };
            payload["parameters"] = window["model"];

            const ajaxSettings: any = {
                type: "POST",
                url: "/PaymentGateway/PostPayment",
                contentType: "application/json",
                data: JSON.stringify(payload),
                success: reply => {
                    $("#Clearent-close-button").prop("disabled", false);

                    if (reply.isRedirect) {
                        window.location.href = reply.redirectUrl;
                    }
                    else {
                        if (reply) {
                            let paymentResponse = {
                                amount: amount, authCode: authCode, when: new Date().getTime()
                            }
                            window.localStorage.setItem('paymentResponse', JSON.stringify(paymentResponse));

                            ClearentBehavior.goBack();
                        }
                    }
                },
                error: err => {
                    console.log(err.status + " : " + err.statusText);
                },
                always: () => {

                }
            };
            $.ajax(ajaxSettings);
        }

    }

}


export class DispatchClearentResponseHandler extends ClearentPaymentResponseHandlerBase {


    clearentOnSuccess(responseRaw: string, responseJson: ClearentResponse.Response): void {
        super.clearentOnSuccess(responseRaw, responseJson);
    }
}

export class AgreementClearentResponseHandler extends ClearentPaymentResponseHandlerBase {

    clearentOnSuccess(responseRaw: string, responseJson: ClearentResponse.Response): void {
        super.clearentOnSuccess(responseRaw, responseJson);
    }
}

export class ClearentConstants {
    public static chargeKeyField = "purchase-order";
    public static expDateField = "exp-date";
    public static lastFourDigitsField = "last-four";
    public static cardTypeField = "card-type";
    public static tokenIdField = "token-id";
    public static avsZip = "avs-zip";
    public static tokenField = "token";
    public static redirectUrl: string;
    public static entityId: string;
    public static saveCard: boolean;
    public static paymentIndex: string;
}

class ClearentBehavior {
    static goBack(additionalArgs: string = ''): void {

        let redirectUrl: string = ClearentConstants.redirectUrl;
        if (additionalArgs) {
            if (UrlHelper.containsQueryString(redirectUrl)) {
                if (additionalArgs[0] != '&') {
                    additionalArgs = '&' + additionalArgs;
                }
            }
            else {
                redirectUrl += '?';

            }

        }
        window.location.href = redirectUrl + additionalArgs;
    }

    static logError(error: string): void {
        console.log(error);
        $('#spanError').text(error);
        $('#spanError').show();
    }

    static get isForMobile(): boolean {
        return window.location.pathname.toLowerCase() === "/paymentgateway/clearentpaymentmobile";
    }

    static navigateToMobile(responseJson: ClearentResponse.Response) {
        let amount = responseJson.payload.transaction.amount;
        let authCode = responseJson.payload.transaction['authorization-code'];
        let id = responseJson.payload.transaction['id'];
        let status = responseJson['status'];

        let queryString = `amount=${amount}&authcode=${authCode}&chargeKey=${responseJson.payload.transaction[ClearentConstants.chargeKeyField]}&id=${id}&status=${status}`;

        if (ClearentConstants.saveCard) {
            const token = responseJson.payload.tokenResponse;

            let expDate: string;
            let lastFour: string;
            let tokenId: string;
            let cardType: string;
            let zipCode: string;

            if (!token && responseJson.links) {
                let tokenLink: ClearentResponse.Link;

                const tokenLinks = responseJson.links.filter(x => x.rel === "token");

                if (tokenLinks.length >= 1) {
                    tokenLink = tokenLinks[0];

                    if (tokenLink) {
                        expDate = responseJson.payload.transaction[ClearentConstants.expDateField];
                        lastFour = responseJson.payload.transaction[ClearentConstants.lastFourDigitsField];
                        tokenId = tokenLink.id;
                        cardType = responseJson.payload.transaction[ClearentConstants.cardTypeField];
                        zipCode = responseJson.payload.transaction.billing.zip;
                    }
                }
            }
            else {
                expDate = token[ClearentConstants.expDateField];
                lastFour = token[ClearentConstants.lastFourDigitsField];
                tokenId = token[ClearentConstants.tokenIdField];
                cardType = token[ClearentConstants.cardTypeField];
                zipCode = token[ClearentConstants.avsZip];
            }

            if (ClearentConstants.saveCard) {
                queryString += `&saveCard=${ClearentConstants.saveCard}`;
            }

            if (lastFour) {
                queryString += `&lastFour=${lastFour}`;
            }

            if (cardType) {
                queryString += `&cardType=${cardType}`;
            }

            if (lastFour && cardType) {
                const cardMask = cardType + " ************" + lastFour;
                queryString += `&cardMask=${cardMask}`;
            }

            if (expDate) {
                queryString += `&expDate=${expDate}`;
            }

            if (tokenId) {
                queryString += `&tokenId=${tokenId}`;
            }

            if (zipCode) {
                queryString += `&zipcode=${zipCode}`;
            }
        }
        ClearentBehavior.goBack(queryString);
    }
}