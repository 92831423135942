export class Navigation {
    // This function only supports basic query string parsing.  If you need support for encoded URLs and
    // more complex use cases, this function must be enhanced to support them.
    stripQueryParameter(url: string, queryParameterName: string): string {
        if (!url) {
            return null;
        }

        const urlSubstrings = url.split("?");
        if (urlSubstrings.length <= 0) {
            return urlSubstrings[0];
        }

        //The first array element should be the base URL without the query parameters
        let newUrl = urlSubstrings[0];
        //The second array element should be the query parameters
        const queryString = urlSubstrings.length > 1 ?  urlSubstrings[1] : '';
        const querySegments = queryString.split("&");
        let containsQuerySeparator = false;

        for (let querySegmentPosition in querySegments) {
            if (querySegments.hasOwnProperty(querySegmentPosition)) {
                const querySegment = querySegments[querySegmentPosition];
                if (querySegment.toLowerCase().indexOf(queryParameterName.toLowerCase()) !== 0 &&
                    querySegment[queryParameterName.length] !== "=") {
                    if (!containsQuerySeparator) {
                        newUrl += "?";
                        containsQuerySeparator = true;
                    } else {
                        newUrl += "&";
                    }

                    newUrl += querySegment;
                }
            }
        }

        return newUrl;
    }

    addQueryParameter(url: string, queryParameterName: string, queryParameterValue: string): string {
        if (!url) {
            return null;
        }

        if (!queryParameterName || !queryParameterValue) {
            return url;
        }

        const containsQueryString = url.indexOf("?") !== -1;

        let newUrl = url;

        if (containsQueryString) {
            newUrl += "&";
        } else {
            newUrl += "?";
        }

        newUrl += queryParameterName + "=" + queryParameterValue; 

        return newUrl;
    }
}