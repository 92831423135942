import $ from "jquery";
import Promise from "promise-polyfill";
import { BaseViewModel } from "./ViewModels/BaseViewModel";
import { PaymentGatewayProvider } from "./PaymentGatewayProvider";
import { PaymentGatewaySettings } from "./PaymentGatewaySettings";
const global = (window /* browser */) as any;

export class Payroc implements PaymentGatewayProvider {

    public enterPaymentsManually(
        viewModel: BaseViewModel,
        gatewaySettings: PaymentGatewaySettings,
        isBusy: (b: boolean) => any,
        forgeryToken: string,
        onError: (reply: any) => any) {

        this.beginPayment(viewModel, isBusy)
            .then(transaction => this.gotoPaymentPage(viewModel, gatewaySettings, transaction))
            .catch(error => {
                onError(error)
                this.failPayment(error)
            });
    }

    public saveCardInfo(gatewaySettings: PaymentGatewaySettings, paymentMethodId: string, customerId: string, agreementId: string, recurrenceIndex: number) {

        this.beginToken()
            .then(tokenInfo => this.gotoTokenPage(gatewaySettings, tokenInfo, paymentMethodId, customerId, agreementId, recurrenceIndex))
            .catch(error => this.failToken(error));
    }

    private beginPayment(viewModel: BaseViewModel, isBusy: (b: boolean) => any) : Promise<PaymentTransaction> {

        let request = $.post({
            url: "/PaymentGateway/CreateTransaction",
            contentType: "application/json",
            data: JSON.stringify(viewModel)
        });

        return new Promise<PaymentTransaction>(
            (resolve, reject) => { 
                request.done(reply => {
                    if (reply.isRedirect) {
                        reject("redirect");
                        window.location.href = reply.redirectUrl;
                        return;
                    }

                    if (reply.error) {
                        reject(reply.error);
                        return;
                    }

                    if (reply.result && reply.result.chargeKey && reply.result.referenceNumber) {
                        viewModel.chargeKey = reply.result.chargeKey;
                        resolve(new PaymentTransaction(
                            reply.result.chargeKey,
                            reply.result.dispatchModel,
                            reply.result.referenceNumber,
                            reply.result.token
                        ));
                    } else {
                        viewModel.chargeKey = "";
                        // viewModel.invoiceNumber = "";
                        reject("invalid");
                    }
                });

                request.fail(error => {
                    if(isBusy != null){
                        isBusy(false);
                    }
                    reject(error)
                });
            }
        );
    }

    private failPayment(error) {

        console.error('Payroc payment error', error);
    }

    private gotoPaymentPage(
        viewModel: BaseViewModel,
        gatewaySettings: PaymentGatewaySettings,
        transaction: PaymentTransaction) {

        //TODO Replace Hardcoded values
        let isAgreement = viewModel.agreementId != null && viewModel.agreementId.length > 0;
        let hppEcho: any = {
            chargeType: isAgreement ? "Agreement" : "Dispatch",
            chargeKey: transaction.chargeKey,
            chargeReturn: isAgreement ? "Agreement" : "Dispatch",
            dispatchId: isAgreement ? (transaction.dispatchModel as any).DispatchID : viewModel.dispatchId,
            saveForLater: false
        };
        
        let hppHostname = new URL(gatewaySettings.hostUrl).hostname;
        let hppPath = 'v3';
        let hppParams: any = {
            amount: (viewModel.amount * 100).toFixed(),
            avs: gatewaySettings.enableAvs ? "Y" : "N",
            cvv: "Y",
            fixed_amount: "Y",
            reference_number: transaction.referenceNumber,
            return_url: `${window.location.protocol}//${window.location.host}/PaymentGateway/PayrocPayment`,
            terminal_id: gatewaySettings.hppPublicKey,
            tran_type: "S"
        };

        if (viewModel.saveForFutureTransaction) {
            hppEcho.saveForLater = true;
            hppPath += '/tokenpay';
            hppParams.token = transaction.token;
            hppParams.token_action = "ADD";
        } else {
            hppPath += "/pay";
        }

        hppParams.echo = window.btoa(JSON.stringify(hppEcho)).replace(new RegExp("=", "g"), "");

        let hppQuery = Object.entries(hppParams)
            .map(item => `${item[0]}=${encodeURIComponent(item[1].toString())}`)
            .join('&');

        let hppUrl = `https://${hppHostname}/${hppPath}?${hppQuery}`;

        window.location.href = hppUrl;
    }

    private beginToken(): Promise<TokenInfo> {

        let request = $.post({
            url: "/PaymentGateway/PayrocTokenStart",
            contentType: "application/json"
        });

        return new Promise<TokenInfo>((resolve, reject) => {
            request.done(reply => {
                if (reply.error)
                    reject(reply.error);
                else
                    resolve(new TokenInfo(reply.referenceNumber, reply.token));
            });

            request.fail(error => {
                reject(error)
            });
        });
    }

    private failToken(error) {
        debugger
    }

    private gotoTokenPage(gatewaySettings: PaymentGatewaySettings, tokenInfo: TokenInfo, paymentMethodId: string, customerId: string, agreementId: string, recurrenceIndex: number) {

        let hppEcho = { agreementId, customerId, paymentMethodId, recurrenceIndex };
        let hppHostname = new URL(gatewaySettings.hostUrl).hostname;
        let hppPath = 'v3/token';
        let hppParams: any = {
            avs: gatewaySettings.enableAvs ? "Y" : "N",
            cvv: "Y",
            echo: window.btoa(JSON.stringify(hppEcho)).replace(new RegExp("=", "g"), ""),
            reference_number: tokenInfo.referenceNumber,
            return_url: `${window.location.protocol}//${window.location.host}/PaymentGateway/PayrocTokenFinish`,
            terminal_id: gatewaySettings.hppPublicKey,
            token: tokenInfo.token,
            token_action: "ADD"
        };

        let hppQuery = Object.entries(hppParams)
            .map(item => `${item[0]}=${encodeURIComponent(item[1].toString())}`)
            .join('&');

        let hppUrl = `https://${hppHostname}/${hppPath}?${hppQuery}`;

        window.location.href = hppUrl;
    }
}

class PaymentTransaction {

    private _chargeKey: string;
    private _dispatchModel: object;
    private _referenceNumber: string;
    private _token: string;

    constructor(
        chargeKey: string,
        dispatchModel: object,
        referenceNumber: string,
        token: string
    ) {
        this._chargeKey = chargeKey;
        this._dispatchModel = dispatchModel;
        this._referenceNumber = referenceNumber;
        this._token = token;
    }

    public get chargeKey() { return this._chargeKey }
    public get dispatchModel() { return this._dispatchModel }
    public get referenceNumber() { return this._referenceNumber }
    public get token() { return this._token }
}

class TokenInfo {

    private _referenceNumber: string;
    private _token: string;

    constructor(
        referenceNumber: string,
        token: string
    ) {
        this._referenceNumber = referenceNumber;
        this._token = token;
    }

    public get referenceNumber() { return this._referenceNumber }
    public get token() { return this._token }
}