import $ from "jquery";
import { PaymentGatewayProvider } from "./PaymentGatewayProvider";
import { ClearentResponseHandlerStrategy, ClearentPaymentResponseHandlerBase, AgreementClearentResponseHandler, DispatchClearentResponseHandler, ClearentSavePaymentOnlyResponseHandler, ClearentConstants } from "./ClearentObj/ClearentResponseHandler";
import { ClearentViewModel } from "./ClearentObj/ClearentViewModel";
import { UrlHelper } from "./../Helpers";
import { IClearentButton } from "./ClearentObj/IClearentButton";
import { IClearentSetupViewModel, IClearentSaveCardViewModel } from "./ClearentObj/ClearentSetupViewModel";

export class Clearent implements PaymentGatewayProvider {

    setupForSaveCard(clearentForm: IClearentButton, clearentSetupViewModel: IClearentSaveCardViewModel): void {

        const global = (window /* browser */) as any;
        var clearentParams = {
            "pk": clearentSetupViewModel.hppPublicKey,
            //"pay-button-parent": "paymentCell",
            "card-token-only": true,
            "heading-text": "Enter card information below",
            "card-acceptance-label": "",
            //"description": ""
        };
        $('div.payment-info').hide();
        clearentForm.payButton(clearentParams);

        ClearentResponseHandlerStrategy.paymentHandlerStrategyType = new ClearentSavePaymentOnlyResponseHandler();
        this.SetClearentEvents(clearentSetupViewModel.redirectUrl, clearentSetupViewModel.entityId, true, clearentSetupViewModel.paymentIndex);
    }

    setupForPayment(clearentForm: IClearentButton, clearentSetupViewModel: IClearentSetupViewModel): void {


        var clearentResponseStrategyType = clearentSetupViewModel.isAgreement
            ? new AgreementClearentResponseHandler()
            : new DispatchClearentResponseHandler();


        var clearentParams = {
            "pk": clearentSetupViewModel.hppPublicKey,
            "pay-button-parent": "paymentCell",
            "check-field": ClearentConstants.chargeKeyField,
            "amount": clearentSetupViewModel.amount.toFixed(2),//this is here as we have seen weird issues when this was set as a property later.
            "order-id": (clearentSetupViewModel.isAgreement ? "AGMT " : "WO ") + clearentSetupViewModel.fromValue,
            "customer-id": clearentSetupViewModel.customerName,
            "description": clearentSetupViewModel.description            
        };

        if (clearentSetupViewModel.showSwipeCard)
        {
            clearentForm.setProperty("show-swipe-option", true);
            clearentForm.setProperty("track-format", "IDTECH");
            clearentForm.setProperty("cvc-required", false);
        }

        clearentParams[ClearentConstants.chargeKeyField] = clearentSetupViewModel.chargeKey;
        clearentForm.payButton(clearentParams);

        if (clearentSetupViewModel.enableAvs || clearentSetupViewModel.saveCard) {
            clearentForm.setProperty("require-billing-address", true);
        }else 
        {
             clearentForm.setProperty("show-billing-zip", true);
             clearentForm.setProperty("billing-zip", clearentSetupViewModel.billingZip);
        }
        if (clearentSetupViewModel.saveCard) {

            clearentForm.setProperty("show-save-card-option", true);
        } 

        ClearentResponseHandlerStrategy.paymentHandlerStrategyType = clearentResponseStrategyType;
        this.SetClearentEvents(clearentSetupViewModel.redirectUrl, clearentSetupViewModel.entityId, clearentSetupViewModel.saveCard);
    }

    private SetClearentEvents(redirectUrl: string, entityId: string, saveCard: boolean, paymentIndex: string | null = "") {
        ClearentConstants.saveCard = saveCard;
        ClearentConstants.redirectUrl = redirectUrl;// clearentSetupViewModel.redirectUrl;
        ClearentConstants.entityId = entityId;
        ClearentConstants.paymentIndex = paymentIndex;
       
        const global = (window /* browser */) as any;
        let clearentReponseStrategy = new ClearentResponseHandlerStrategy();

        global.ClearentOnSuccess = clearentReponseStrategy.clearentOnSuccess;
        global.ClearentOnError = clearentReponseStrategy.clearentOnError;
        global.ClearentOnPopupClosed = clearentReponseStrategy.ClearentOnPopupClosed;
    }

    saveCardInfo(entityId: string, returnUrl: string, paymentIndex: string) {
        let params = new Map<string, string>();
        params.set("entityId", entityId);
        params.set("redirectUrl", returnUrl);
        params.set("paymentIndex", paymentIndex);
        UrlHelper.post("/PaymentGateway/ClearentSaveCardInfo", params);
    }

    enterPaymentsManually(viewModelExternal: ClearentViewModel,
        isBusy: (b: boolean) => any,
        forgeryToken: string,
        onError: (reply: any) => any,
        returnUrl: string) {
        var viewModel: ClearentViewModel = new ClearentViewModel(viewModelExternal);
        viewModel.companyName = localStorage.CompanySettings ? JSON.parse(localStorage.CompanySettings).CompanyName : "";

        if (viewModel.chargeKey) {
            this.postClearentPayment(viewModel, returnUrl);
        } else {
            const ajaxSettings: any = {
                type: "POST",
                url: "/PaymentGateway/CreateTransaction",
                contentType: "application/json",
                data: JSON.stringify(viewModel),
                success: reply => {
                    if (reply.isRedirect) {
                        window.location.href = reply.redirectUrl;
                    } else {
                        if (reply) {
                            if (onError && reply.error) {
                                onError(reply);
                            } else {
                                viewModel.chargeKey = "";
                                if (reply.result && reply.result.chargeKey) {
                                    viewModel.chargeKey = reply.result.chargeKey;
                                }
                                if (reply.result && reply.result.invoiceNumber)
                                {
                                    viewModel.invoiceNumber = reply.result.invoiceNumber;
                                }
                                this.postClearentPayment(viewModel, returnUrl);
                            }
                        }
                    }
                },
                error: err => {
                    console.log(err.status + " : " + err.statusText);
                },
                always: () => {
                    if (isBusy) {
                        isBusy(false);
                    }
                }
            };
            $.ajax(ajaxSettings);
        }
    }

    private postClearentPayment(viewModel: ClearentViewModel, returnUrl: string): void {
        let params = new Map<string, string>();
        params.set("isAgreement", viewModel.isAgreement.toString());
        params.set("chargeKey", viewModel.chargeKey);
        params.set("redirectUrl", returnUrl);
        params.set("agreementId", viewModel.agreementId);
        params.set("dispatchId", viewModel.dispatchId);
        params.set("fromName", viewModel.fromName);
        params.set("fromValue", viewModel.fromValue);
        params.set("customerName", viewModel.customerName);
        params.set("amount", viewModel.amount.toFixed(2));
        params.set("selectedPaymentMethodId", viewModel.selectedPaymentMethodId);
        params.set("entityId", viewModel.entityId);
        params.set("recurranceId", viewModel.recurranceId);
        params.set("saveCard", viewModel.saveForFutureTransaction.toString());
        params.set("isMissed",
            (viewModel.isMissed === undefined || viewModel.isMissed === null)
            ? false.toString()
                : viewModel.isMissed.toString());
        params.set("invoiceNumber", viewModel.invoiceNumber);

        UrlHelper.post("/PaymentGateway/ClearentPayment", params);
    }

    //enterPaymentsManuallyMobileTest() {

    //    function uuidv4() {
    //        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    //            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    //            return v.toString(16);
    //        });
    //    }

    //    console.log(uuidv4())
    //    let params = new Map<string, string>();
    //    params.set("chargeKey", uuidv4());
    //    params.set("redirectUrl", "http://www.google.com");
    //    params.set("dispatchId", 'dispatch id');
    //    params.set("fromValue", 'Test WO');
    //    params.set("amount", '1.00');
    //    params.set("HppPublicKey", '307a301406072a8648ce3d020106092b240303020801010c0362000448cfd0ade8e09853959691ff3df02a492fac799fa428c21f1001b1559002a9a3736f587cf21cd30f4df4ed187b26e6dc5190b91f32f349193d2d73fc4277128fea01e88c015a484d43ef97c3c72ca8344e2a3f2db28449671cd9719ef4e957ba');
    //    params.set("IsAvsEnabled", 'true');
    //    params.set("RESTParamsModel.DeviceID", "device id");
    //    params.set("SaveCard", "true");
    //    UrlHelper.post("/PaymentGateway/ClearentPaymentMobile", params);

    //}

}

